import PropTypes from 'prop-types';
import Icon from './Icon';

export default function IcPen({variant, className, stroke, fill, whites}) {
  const VARIANTS = {
    multi: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m15.412 4.2336-6.8493 8.3684s1.0812.33641 1.7195 1.0278c.63822.6914 1.0738 1.8381 1.0738 1.8381l8.4083-6.882c1.4159-1.1588 1.5218-3.2867.2281-4.5804s-3.4216-1.1878-4.5804.22806z"
          fill={fill}
        />
        <path
          d="m17.598 2.0332c-1.129.056215-2.2355.56833-3.0156 1.5215l-7.3066 8.9277c.91617-.04506 1.6507.15832 2.291.58208l6.6738-8.1524c.7574-.92536 2.1486-.99399 2.9941-.14844.8456.84555.77696 2.2348-.14844 2.9922l-8.209 6.7187c.52851.78564.62487 1.4224.67616 2.2149l8.8902-7.2754c1.9063-1.5602 2.0485-4.4241.30664-6.166-.87095-.87094-2.0233-1.2711-3.1523-1.2148z"
          fill={stroke}
        />
        <path
          d="m10.26 19.492c.3948-.3639.7099-.8057.9254-1.2974.2156-.4918.3268-1.0229.3268-1.5598s-.1112-1.068-.3268-1.5597c-.2155-.4918-.5306-.9335-.9254-1.2974-.36384-.3948-.8056-.7099-1.2974-.9254-.49176-.2155-1.0228-.3268-1.5598-.3268s-1.068.1113-1.5597.3268c-.49176.2155-.93351.5306-1.2974.9254-1.4286 1.4285-1.4286 7.1428-1.4286 7.1428s5.7143 0 7.1428-1.4285z"
          fill={whites}
        />
        <path
          d="m5.4142 11.87c.62734-.2749 1.3049-.4169 1.9898-.4169.68495 0 1.3625.142 1.9898.4169.61406.2691 1.167.6599 1.6255 1.1486.4887.4585.8795 1.0114 1.1486 1.6255.2749.6273.4169 1.3048.4169 1.9898 0 .6849-.142 1.3624-.4169 1.9898-.2723.6212-.6691 1.1798-1.1658 1.6414-.5665.5563-1.4172.878-2.1778 1.0855-.81195.2214-1.7213.363-2.552.4553-.83607.0929-1.6215.139-2.196.1619-.28803.0116-.5249.0174-.69088.0203-.08302.0014-.14842.0022-.19374.0025l-.05264 4e-4h-.02041c-.00017 0-.00033 0-.00033-1.0714-1.0714 0-1.0714-2e-4-1.0714-3e-4v-.006l5e-5-.0145.00033-.0526c.00037-.0453.00111-.1107.00257-.1937.00291-.166.0087-.4029.02022-.6909.02298-.5745.06905-1.36.16195-2.196.0923-.8308.23386-1.7401.45531-2.552.20744-.7607.52916-1.6113 1.0855-2.1778.46159-.4968 1.0202-.8935 1.6414-1.1658zm-2.2959 9.0496-1.0714-3e-4c0 .5917.48002 1.0717 1.0718 1.0717zm1.103-1.103c.51041-.0241 1.148-.0664 1.8144-.1405.77641-.0862 1.5635-.2125 2.2248-.3929.70509-.1923 1.0952-.402 1.243-.5498.01027-.0103.02076-.0203.03145-.0302.28593-.2635.5141-.5835.6702-.9397.1561-.3561.2367-.7408.2367-1.1296 0-.3889-.0806-.7735-.2367-1.1297s-.38427-.6761-.6702-.9397c-.02138-.0197-.04195-.0402-.06166-.0616-.26355-.2859-.58349-.5141-.93966-.6702-.35616-.1561-.7408-.2367-1.1297-.2367-.38887 0-.77351.0806-1.1297.2367s-.67611.3843-.93966.6702c-.00985.0107-.01992.0212-.0302.0314-.14784.1479-.35753.538-.54983 1.2431-.18035.6613-.30664 1.4484-.39291 2.2248-.07405.6664-.11638 1.304-.14049 1.8144z"
          fill={stroke}
        />
        <path
          d="m7.242 16.796c.41841.4185.41841 1.0969-1e-5 1.5153l-3.366 3.366c-.41842.4184-1.0968.4184-1.5152 0-.41841-.4185-.41841-1.0969 1e-5-1.5153l3.366-3.366c.41842-.4184 1.0968-.4184 1.5152 0z"
          fill={stroke}
        />
      </svg>
    ),
    single: (
      <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m15.412 4.2336-6.8493 8.3684s1.0812.33641 1.7195 1.0278c.63822.6914 1.0738 1.8381 1.0738 1.8381l8.4083-6.882c1.4159-1.1588 1.5218-3.2867.2281-4.5804s-3.4216-1.1878-4.5804.22806z"
          fill="transparent"
        />
        <path d="m17.598 2.0332c-1.129.056215-2.2355.56833-3.0156 1.5215l-7.3066 8.9277c.91617-.04506 1.6507.15832 2.291.58208l6.6738-8.1524c.7574-.92536 2.1486-.99399 2.9941-.14844.8456.84555.77696 2.2348-.14844 2.9922l-8.209 6.7187c.52851.78564.62487 1.4224.67616 2.2149l8.8902-7.2754c1.9063-1.5602 2.0485-4.4241.30664-6.166-.87095-.87094-2.0233-1.2711-3.1523-1.2148z" />
        <path
          d="m10.26 19.492c.3948-.3639.7099-.8057.9254-1.2974.2156-.4918.3268-1.0229.3268-1.5598s-.1112-1.068-.3268-1.5597c-.2155-.4918-.5306-.9335-.9254-1.2974-.36384-.3948-.8056-.7099-1.2974-.9254-.49176-.2155-1.0228-.3268-1.5598-.3268s-1.068.1113-1.5597.3268c-.49176.2155-.93351.5306-1.2974.9254-1.4286 1.4285-1.4286 7.1428-1.4286 7.1428s5.7143 0 7.1428-1.4285z"
          fill="transparent"
        />
        <path d="m5.4142 11.87c.62734-.2749 1.3049-.4169 1.9898-.4169.68495 0 1.3625.142 1.9898.4169.61406.2691 1.167.6599 1.6255 1.1486.4887.4585.8795 1.0114 1.1486 1.6255.2749.6273.4169 1.3048.4169 1.9898 0 .6849-.142 1.3624-.4169 1.9898-.2723.6212-.6691 1.1798-1.1658 1.6414-.5665.5563-1.4172.878-2.1778 1.0855-.81195.2214-1.7213.363-2.552.4553-.83607.0929-1.6215.139-2.196.1619-.28803.0116-.5249.0174-.69088.0203-.08302.0014-.14842.0022-.19374.0025l-.05264 4e-4h-.02041c-.00017 0-.00033 0-.00033-1.0714-1.0714 0-1.0714-2e-4-1.0714-3e-4v-.006l5e-5-.0145.00033-.0526c.00037-.0453.00111-.1107.00257-.1937.00291-.166.0087-.4029.02022-.6909.02298-.5745.06905-1.36.16195-2.196.0923-.8308.23386-1.7401.45531-2.552.20744-.7607.52916-1.6113 1.0855-2.1778.46159-.4968 1.0202-.8935 1.6414-1.1658zm-2.2959 9.0496-1.0714-3e-4c0 .5917.48002 1.0717 1.0718 1.0717zm1.103-1.103c.51041-.0241 1.148-.0664 1.8144-.1405.77641-.0862 1.5635-.2125 2.2248-.3929.70509-.1923 1.0952-.402 1.243-.5498.01027-.0103.02076-.0203.03145-.0302.28593-.2635.5141-.5835.6702-.9397.1561-.3561.2367-.7408.2367-1.1296 0-.3889-.0806-.7735-.2367-1.1297s-.38427-.6761-.6702-.9397c-.02138-.0197-.04195-.0402-.06166-.0616-.26355-.2859-.58349-.5141-.93966-.6702-.35616-.1561-.7408-.2367-1.1297-.2367-.38887 0-.77351.0806-1.1297.2367s-.67611.3843-.93966.6702c-.00985.0107-.01992.0212-.0302.0314-.14784.1479-.35753.538-.54983 1.2431-.18035.6613-.30664 1.4484-.39291 2.2248-.07405.6664-.11638 1.304-.14049 1.8144z" />
        <path d="m7.242 16.796c.41841.4185.41841 1.0969-1e-5 1.5153l-3.366 3.366c-.41842.4184-1.0968.4184-1.5152 0-.41841-.4185-.41841-1.0969 1e-5-1.5153l3.366-3.366c.41842-.4184 1.0968-.4184 1.5152 0z" />
      </svg>
    ),
  };

  return (
    <Icon className={className} label="Pen icon">
      {VARIANTS[variant]}
    </Icon>
  );
}

IcPen.propTypes = {
  className: Icon.propTypes.className,
  stroke: PropTypes.string,
  fill: PropTypes.string,
  whites: PropTypes.string,
  variant: PropTypes.string,
};

IcPen.defaultProps = {
  className: '',
  stroke: '#182C53',
  fill: '#E8EDF4',
  whites: '#FFFFFF',
  variant: 'single',
};
